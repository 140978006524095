import { onlyNumbersString } from 'utils';
import { API } from 'config';
import axios from 'axios';

const SPREADSHEET_ID = 1;
const PAYROLL_SYSTEM_OPTION_SPREADSHEET = 'Planilha';
const PAYROLL_SYSTEM_OPTION_INTEGRATION = 'Integração';

export const handleCustomerOverview = (data) => {
	if (!data) {
		return null;
	}

	const { customer, proposals, shoppingCart, currentValidOffer, hasAgreementLeave, hasSelfieBiometryIntegration, hasAccountBtg } = data;

	const result = {
		customer: {
			id: customer.id,
		},
		user: {
			id: customer.user.id,
			name: customer.agreement?.employeeFullName || customer.user?.name,
			document: customer?.agreement?.employeeDocumentNumber || customer.user?.nationalDocument,
			birthDate: customer.user?.birthDate,
			phone: customer.user?.phoneNumber,
			email: customer.user?.email,
		},
		agreement: customer.agreement?.id
			? {
					companyName: customer.agreement.companyName,
					motherName: customer.agreement.employeeMotherFullName,
					registeredPosition: customer.agreement.registeredPosition,
					cbo: customer.agreement.cbo,
					consignableFreeMargin: customer.agreement.consignableFreeMargin,
					utilizedLimit: customer.agreement.utilizedLimit,
					monthlyIncome: customer.agreement.monthlyIncome,
					admissionDate: customer.agreement.bondBeginDate,
					isDisabled: customer.agreement.isDisabled,
					leaveStartDate: customer.agreement.leaveStartDate,
					leaveFinishDate: customer.agreement.leaveFinishDate,
					payrollSystemOption:
						customer.agreement.payrollSystemOption == SPREADSHEET_ID
							? PAYROLL_SYSTEM_OPTION_SPREADSHEET
							: PAYROLL_SYSTEM_OPTION_INTEGRATION,
			  }
			: null,
		proposals,
		shoppingCart,
		currentValidOffer,
		hasAgreementLeave,
    hasSelfieBiometryIntegration,
    hasAccountBtg
	};

	return result;
};

const searchCpf = async (cpf) => {
	const accessToken = sessionStorage.getItem('ALI_ACCESS_TOKEN');

	try {
		const response = await axios
			.post(
				API.GET_USER_OVERVIEW,
				{
					documentNumber: onlyNumbersString(cpf),
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			)
			.catch((e) => {
				if (!e.response) return { statusCode: 403 };

				return { ...e?.response, statusCode: e?.response.status };
			});

		if (response && response.statusCode == 403) {
			return { invalidNetwork: true };
		}

		if (!response?.data || response?.data?.status == 'error') {
			return null;
		}

		const result = handleCustomerOverview(response.data.data);
    sessionStorage.setItem("HAS_SELFIE_BIOMETRY_INTEGRATION", result.hasSelfieBiometryIntegration);
		return result;
	} catch (error) {
		console.error(error);
	}

	return false;
};

export default searchCpf;
