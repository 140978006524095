import React, { useContext } from "react";
import { ClientContext } from "contexts/ClientProvider";
import { formatDisplayDate } from "utils";
import {
	TableWrapper,
	Row,
	Td,
	TdLeft,
	TableTitle,
	NotInformed,
} from "./styles";

import AgreementNotFount from "../AgreementNotFound";

const AgreementRecord = ({ onCreateData }) => {
	const [client] = useContext(ClientContext);

	const dataAgreement = [
		[1, "Convênio:", client.agreement ? client.agreement.companyName : null],
		// [2, 'Nome da mãe:', client.agreement.motherName],
		[
			2,
			"Cargo:",
			client.agreement ? client.agreement.registeredPosition : null,
		],
		[
			3,
			"Margem livre:",
			client.agreement
				? client.agreement.consignableFreeMargin?.toLocaleString("pt-BR", {
						style: "currency",
						currency: "BRL",
				  })
				: null,
		],
		[
			4,
			"Limite utilizado:",
			client.agreement
				? client.agreement.utilizedLimit?.toLocaleString("pt-BR", {
						style: "currency",
						currency: "BRL",
				  })
				: null,
		],
		[
			5,
			"Data da admissão:",
			client.agreement
				? formatDisplayDate(client.agreement.admissionDate)
				: null,
		],
		[
			6,
			"Tipo da integração:",
			client.agreement ? client.agreement.payrollSystemOption : null,
		],
    [
      7,
      "Correntista BTG:",
      client.hasAccountBtg ? "Sim" : "Não"
    ]
	];

	return (
		<TableWrapper>
			<TableTitle>
				Informações de Convênio
				{/* <EditButton onClick={handleEditOnClick} /> */}
			</TableTitle>
			{/* <AgreementEdit data={{motherName: client.agreement.motherName}} loading={loading} isOpen={editIsOpen} onClose={handleEditOnClose} onOK={handleEditOnOK} /> */}
			{!client.agreement && <AgreementNotFount onCreateData={onCreateData} />}
			{client.agreement &&
				dataAgreement.map(([id, fieldName, value]) => (
					<Row key={id}>
						<TdLeft>{fieldName}</TdLeft>
						<Td>{value || <NotInformed>(não informado)</NotInformed>}</Td>
					</Row>
				))}
		</TableWrapper>
	);
};
export default AgreementRecord;
